import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Box,
  MenuItem,
  Avatar,
  Typography,
  DialogContent,
  IconButton,
} from '@mui/material';
import placeholder_image from "../../../images/placeholder_image.png";
import "../../../css/CreateCardStepper.css";
import ShapeWithImage from '../../ShapeWithImage';
import { getBase64OfImage, getSvgImageUri } from '../../../Utility/config';

const CardStyleForm = ({ onSave, cardData, handleBack, group_data }) => {
  const enable_style_edit = cardData?.group ? group_data?.enable_style_edit || cardData?.group?.enable_style_edit || 0 : 1;
  const [imageSrcData, setImageSrcData] = useState(cardData?.basicinfo?.cover || placeholder_image || "");


  useEffect(() => {
    
    getBase64OfImage(cardData?.basicinfo?.cover).then((base64) => {
      setImageSrcData(base64)
  });
  }, [imageSrcData]);

  const [formData, setFormData] = useState({
    avatar_shape: !enable_style_edit ? group_data?.avatar_shape : cardData?.cardstyle?.avatar_shape || group_data?.avatar_shape || 'circle',
    avatar_align: !enable_style_edit ? group_data?.avatar_align : cardData?.cardstyle?.avatar_align || group_data?.avatar_align || 'center',
    cover_border_color: !enable_style_edit ? group_data?.cover_border_color : cardData?.cardstyle?.cover_border_color || group_data?.cover_border_color || '#000000',
    avatar_border_color: !enable_style_edit ? group_data?.avatar_border_color : cardData?.cardstyle?.avatar_border_color || group_data?.avatar_border_color || '#000000',
    background_color: !enable_style_edit ? group_data?.background_color : cardData?.cardstyle?.background_color || group_data?.background_color || '#ffffff',
    text_color: !enable_style_edit ? group_data?.text_color : cardData?.cardstyle?.text_color || group_data?.text_color || '#000000',
    style_shape: cardData?.cardstyle?.style_shape || 'none',
  });

  const styles = ['shape1', 'shape2', 'shape3', 'shape4', 'shape5', "none"];
  const shapes = ['none', 'circle', 'square', 'rectangle'];
  const alignments = ['none', 'left', 'center', 'right'];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    onSave(formData);
  };



  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 2, flexWrap: 'wrap' }}>
        <Box sx={{
          width: { xs: '100%', sm: '48%' },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          padding: 2,
        }}>

          <TextField
            select
            label="Profile Style Shape"
            name="style_shape"
            value={formData.style_shape}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={enable_style_edit ? false : true}
          >
            {styles.map((style) => (
              <MenuItem key={style} value={style}>
                {style.charAt(0).toUpperCase() + style.slice(1)}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Profile Image Shape"
            name="avatar_shape"
            value={formData.avatar_shape}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={!enable_style_edit || (formData?.style_shape && formData?.style_shape !== "none") ? true : false}

          >
            {shapes.map((shape) => (
              <MenuItem key={shape} value={shape}>
                {shape.charAt(0).toUpperCase() + shape.slice(1)}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            label="Profile Image Alignment"
            name="avatar_align"
            value={formData.avatar_align}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={!enable_style_edit || (formData?.style_shape && formData?.style_shape !== "none") ? true : false}

          >
            {alignments.map((align) => (
              <MenuItem key={align} value={align}>
                {align.charAt(0).toUpperCase() + align.slice(1)}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            type="color"
            label="Cover Border Color"
            name="cover_border_color"
            value={formData?.cover_border_color}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={enable_style_edit ? false : true}

          />

          <TextField
            type="color"
            label="Profile Border Color"
            name="avatar_border_color"
            value={formData.avatar_border_color}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={enable_style_edit ? false : true}

          />

          <TextField
            type="color"
            label="Card Background Color"
            name="background_color"
            value={formData.background_color}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={enable_style_edit ? false : true}

          />

          <TextField
            type="color"
            label="Text Color"
            name="text_color"
            value={formData.text_color}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={enable_style_edit ? false : true}

          />
        </Box>

        {/*********  design part   *********/}

        <Box
          sx={{
            backgroundColor: formData.background_color,
            width: { xs: '100%', sm: '48%' },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            padding: 2,
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: formData?.style_shape === "none" ? 150 : 300,
              backgroundImage: formData?.style_shape === "none" ? `url(${cardData?.basicinfo?.cover || placeholder_image})` : getSvgImageUri(imageSrcData, formData?.style_shape, formData.cover_border_color),
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: formData?.style_shape === "none" ? 2 : 0,
              border: `${formData?.style_shape === "none" ? "5px" : "0px"} solid ${formData.cover_border_color}`,
              marginBottom: 2,
              position: 'relative',
              padding: 1
            }}
          >
            {formData?.style_shape === "none" || !formData?.style_shape ?
              <Avatar
                src={cardData?.basicinfo?.avatar || placeholder_image}
                alt="Profile"
                sx={{
                  width: formData.avatar_shape === 'rectangle' ? 120 : 100,
                  height: 100,
                  border: `4px solid ${formData.avatar_border_color}`,
                  borderRadius: formData.avatar_shape === 'circle' ? '50%' : formData.avatar_shape === 'square' ? '0%' : '0%',
                  position: 'absolute',
                  bottom: -40,
                  // Align based on the selected value of formData.avatar_align
                  left: formData.avatar_align === 'left' ? 20 : formData.avatar_align === 'right' ? 'auto' : '50%',
                  right: formData.avatar_align === 'right' ? 20 : 'auto',
                  transform: formData.avatar_align === 'center' ? 'translateX(-50%)' : 'none',
                }}
              />
              :
              <>
                <Avatar
                  src={cardData?.basicinfo?.avatar || placeholder_image}
                  alt="Profile"
                  sx={{
                    width: formData.avatar_shape === 'rectangle' ? 120 : 100,
                    width: formData.style_shape ? 90 : 100,
                    height: formData.style_shape ? 90 : 100,
                    border: `4px solid ${formData?.avatar_border_color}`,
                    borderRadius: formData?.style_shape === "shape1" ? '7%' : formData?.style_shape === "shape2" ? '50%' : formData?.style_shape === "shape3" ? '50%' : formData?.style_shape === "shape4" ? '50%' : formData?.style_shape === "shape5" ? '7%' : '0%',
                    position: 'absolute',
                    bottom: formData?.style_shape === "shape1" ? 100 : formData?.style_shape === "shape2" ? 100 : formData?.style_shape === "shape3" ? 100 : formData?.style_shape === "shape4" ? -20 : formData?.style_shape === "shape5" ? 120 : "auto",//shap1
                    left: formData?.style_shape === "shape1" ? 10 : formData?.style_shape === "shape2" ? 10 : formData?.style_shape === "shape3" ? 10 : 'auto',//shap1
                    right: formData?.style_shape === "shape4" ? 20 : 'auto',

                  }}
                />

                <Box sx={{
                  textAlign: "center",
                  left: formData?.style_shape === "shape1" ? 0 : formData?.style_shape === "shape2" ? 0 : formData?.style_shape === "shape3" ? 0 : 'auto',//shap1
                  position: 'absolute',
                  bottom: formData?.style_shape === "shape1" ? -10 : formData?.style_shape === "shape2" ? 0 : formData?.style_shape === "shape3" ? 0 : formData?.style_shape === "shape4" ? 0 : formData?.style_shape === "shape5" ? 0 : "auto",//shap1
                }}>
                  <Typography variant="h5" sx={{ fontWeight: 'bold', color: formData?.cardstyle?.text_color || '#000000' }}>
                    {cardData?.basicinfo?.name || ""}
                  </Typography>

                  <Typography variant="subtitle1" sx={{ color: formData?.cardstyle?.text_color || '#000000', marginBottom: 2 }}>
                    {cardData?.basicinfo?.job_title || ""}
                  </Typography>
                </Box>
              </>
            }

          </Box>

          {(formData?.style_shape === "none" || !formData?.style_shape) && (
            <>
              <Typography variant="h5" sx={{ textAlign: formData.avatar_align, marginTop: 5, fontWeight: 'bold', color: formData.text_color }}>
                {cardData?.basicinfo?.name || ""}
              </Typography>
              <Typography variant="subtitle1" sx={{ textAlign: formData.avatar_align, color: formData.text_color }}>
                {cardData?.basicinfo?.job_title || ""}
              </Typography>
            </>
          )}



          {cardData?.contactway?.length > 0 ? (
            <DialogContent sx={{ padding: 2 }}>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: {
                    xs: 'repeat(2, 1fr)',
                    sm: 'repeat(2, 1fr)',
                    md: 'repeat(3, 1fr)',
                  },
                  gap: { xs: 1, sm: 3 },
                  justifyContent: cardData?.contactway?.length < 3 ? 'center' : 'start',
                  maxWidth: { xs: 200, sm: 500, md: 700 },
                  margin: '0 auto',
                }}
              >
                {cardData?.contactway?.map((contact) => (
                  <Box
                    key={contact.name}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                      padding: { xs: 0.5, sm: 1 },
                      borderRadius: 2,
                      boxShadow: 2,
                      backgroundColor: '#f0f0f0',
                      '&:hover': {
                        backgroundColor: '#e0e0e0',
                        cursor: 'pointer',
                      },
                    }}
                  >
                    <IconButton>
                      <img src={contact.icon} alt={contact.name} style={{ width: 50, height: 50 }} />
                    </IconButton>
                    <Typography variant="body2" sx={{ marginTop: { xs: 0, sm: 1 } }}>
                      {contact.title || contact.name}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </DialogContent>
          ) : true}
        </Box>

      </Box>
      <div className='step-button-container'>
        <Button
          onClick={() => handleBack()}
          variant="contained"
          color="primary"
          sx={{ mt: 2, alignSelf: "right" }}
        >
          BACK
        </Button>
        <Button
          onClick={handleSubmit}
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 2, alignSelf: "center" }}
        >
          NEXT
        </Button>
      </div>
    </>

  );
};

export default CardStyleForm;
