import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  useMediaQuery
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import logo_icon from "../../images/aurages.png";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import i18next from "i18next";
import { t } from 'i18next';

const NavBarComponent = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [languageAnchorEl, setLanguageAnchorEl] = useState(null);
  const [language, setLanguage] = React.useState('en');
  const [params, setParams] = useSearchParams();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleLanguageClick = (event) => {
    setLanguageAnchorEl(event.currentTarget);
  };

  const handleLanguageClose = () => {
    setLanguageAnchorEl(null);
  };

  // Handle language change
  const handleLanguageChange = (event) => {
    setLanguage(event.target.getAttribute('data-value'));
    i18next.changeLanguage(event.target.getAttribute('data-value'));
    document.querySelector("html").lang = event.target.getAttribute('data-value');
    localStorage.setItem("lang", event.target.getAttribute('data-value'))
    params.set("lang", event.target.getAttribute('data-value'));
    setParams(params);

    if (event.target.getAttribute('data-value') === "ar" || event.target.getAttribute('data-value') === "fa") {
      document.body.classList.remove("latino-font");
      document.body.classList.add("arabic-font");
    } else {
      document.body.classList.add("latino-font");
      document.body.classList.remove("arabic-font");
    }
    setLanguageAnchorEl(null);
  };

  const navItems = [
    { text: t('Home'), onClick: () => navigate("/") },
    { text: t('Groups'), onClick: () => navigate("/groups") },
    { text: t('Plans'), onClick: () => navigate("/plans") },
    { text: t('My Profile'), onClick: () => navigate("/profile") },
  ];

  useEffect(() => {
    const auth_token = localStorage.getItem("auth_token");
    if (!auth_token) {
      navigate("/login");
    }
  }, []);

  const handleLogOut = () => {
    localStorage.removeItem("auth_token");
    navigate("/login")
  }

  return (
    <div className='alignByLang'>
      <AppBar color="" position="static">
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <IconButton edge="start" aria-label="logo" sx={{ mr: 2 }}>
            <img src={logo_icon} alt="Logo" style={{ width: 100, height: 40 }} />
          </IconButton>

          {!isSmallScreen && (
            <div style={{ flexGrow: 1, display: 'flex', justifyContent: "space-between" }}>
              <div>
                {navItems.map((item) => (
                  <Button
                    className='head-font'
                    key={item.text}
                    color="inherit"
                    onClick={item.onClick}
                    sx={{ textTransform: 'none', mx: 1 }}
                  >
                    {item.text}
                  </Button>
                ))}

                <Button
                  className='head-font'
                  color="inherit"
                  onClick={handleLanguageClick}
                  endIcon={<ArrowDropDownIcon />}
                  sx={{ textTransform: 'none', mx: 1 }}
                >
                  {t("Language")}
                </Button>
                <Menu
                  anchorEl={languageAnchorEl}
                  open={Boolean(languageAnchorEl)}
                  onClose={handleLanguageClose}
                >
                  <MenuItem onClick={handleLanguageChange} data-value="en">English</MenuItem>
                  <MenuItem onClick={handleLanguageChange} data-value="ar">Arabic</MenuItem>
                </Menu>
              </div>
              <div>
                <Button className='head-font'
                  color="inherit" onClick={() => handleLogOut()}>
                  {t("Logout")}
                </Button>
              </div>
            </div>
          )}

          {isSmallScreen && (
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>

      <Drawer className='alignByLang' anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <List>
          {navItems.map((item) => (
            <ListItem button key={item.text} onClick={item.onClick}>
              <ListItemText className='head-font'
                primary={item.text} />
            </ListItem>
          ))}

          <ListItem button onClick={handleLanguageClick}>
            <ListItemText className='head-font' primary={t("Language")} />
            <ArrowDropDownIcon />
          </ListItem>
          <Menu
            anchorEl={languageAnchorEl}
            open={Boolean(languageAnchorEl)}
            onClose={handleLanguageClose}
          >
            <MenuItem onClick={handleLanguageChange} data-value="en">English</MenuItem>
            <MenuItem onClick={handleLanguageChange} data-value="ar">Arabic</MenuItem>
          </Menu>

          <ListItem button onClick={() => handleLogOut()}>
            <ListItemText className='head-font'
              primary={t("Logout")} />
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
};

export default NavBarComponent;
