import React, { useEffect } from 'react';
import { TextField, Button, MenuItem, IconButton, Menu } from '@mui/material';
import { Facebook, Google, Apple, Language } from '@mui/icons-material';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import "../css/login.css";
import login_image_card from "../images/login_image_card.png";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AURCARDAPIBASE } from '../Utility/config';
import i18next, { t } from "i18next";

const validationSchema = Yup.object({
    email: Yup.string()
        .email('Please enter a valid email address')
        .required('Email is required'),
    password: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .required('Password is required'),
});

const Login = () => {
    const [language, setLanguage] = React.useState('en');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem("auth_token")) {
            navigate("/");
        }
    }, []);

    // Handle language change
    const handleLanguageChange = (event) => {
        setLanguage(event.target.getAttribute('data-value'));
        i18next.changeLanguage(event.target.getAttribute('data-value'));
        document.querySelector("html").lang = event.target.getAttribute('data-value');
        localStorage.setItem("lang", event.target.getAttribute('data-value'))

        if (event.target.getAttribute('data-value') === "ar" || event.target.getAttribute('data-value') === "fa") {
            document.body.classList.remove("latino-font");
            document.body.classList.add("arabic-font");
        } else {
            document.body.classList.add("latino-font");
            document.body.classList.remove("arabic-font");
        }
        setAnchorEl(null);
    };

    // Open language menu
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const login = async (values) => {
        try {
            const response = await axios.post(`${AURCARDAPIBASE}/login`, values);
            if (response?.data?.success) {
                const { token } = response?.data?.data;
                localStorage.setItem('auth_token', token);
                toast.success("Login successfully");
                navigate('/');
            } else {
                toast.error("Login failed");
            }
        } catch (error) {
            toast.error("Login failed");
        }
    };

    return (
        <div className="login alignByLang">
            <ToastContainer />
            <div className="login-container login-bk-image">
                <div className="login-image">
                    <img src={login_image_card} alt='Image_Card' />
                </div>
                <div className="login-form">
                    <div className="login-form-container">
                        <div className="login-header">
                            <h2>{t("Login")}</h2>
                            <div className="language-dropdown">
                                <IconButton onClick={handleMenuOpen} aria-controls="language-menu" aria-haspopup="true">
                                    <Language sx={{ fontSize: 30 }} />
                                </IconButton>
                                <Menu
                                    id="language-menu"
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleMenuClose}
                                >
                                    <MenuItem onClick={handleLanguageChange} data-value="en">English</MenuItem>
                                    <MenuItem onClick={handleLanguageChange} data-value="ar">Arabic</MenuItem>
                                </Menu>
                            </div>
                        </div>
                        <p className="welcome-message">{t("Welcome back! Please enter your details.")}</p>

                        <Formik
                            initialValues={{
                                email: '',
                                password: '',
                            }}
                            validationSchema={validationSchema}
                            onSubmit={login}
                        >
                            {({ handleChange, handleBlur, values }) => (
                                <Form>
                                    <div className="form-group">
                                        <Field
                                            as={TextField}
                                            fullWidth
                                            label={t("Email")}
                                            variant="outlined"
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={!!values.email && !!values.emailError}
                                            helperText={<ErrorMessage name="email" />}
                                            margin="normal"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <Field
                                            as={TextField}
                                            fullWidth
                                            label={t("Password")}
                                            variant="outlined"
                                            name="password"
                                            type="password"
                                            value={values.password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={!!values.password && !!values.passwordError}
                                            helperText={<ErrorMessage name="password" />}
                                            margin="normal"
                                        />
                                    </div>

                                    <Button href="/forgot-password" className="forgot-password-link">
                                        {t("Forgot Password?")}
                                    </Button>
                                    <Button variant="contained" type="submit" fullWidth className="login-button">
                                        {t("Login")}
                                    </Button>
                                </Form>
                            )}
                        </Formik>

                        <div className="social-login-buttons">
                            <IconButton color="primary" aria-label="facebook" sx={{ fontSize: 30 }}>
                                <Facebook />
                            </IconButton>
                            <IconButton color="primary" aria-label="google" sx={{ fontSize: 30 }}>
                                <Google />
                            </IconButton>
                            <IconButton color="primary" aria-label="apple" sx={{ fontSize: 30 }}>
                                <Apple />
                            </IconButton>
                        </div>

                        {/* Forgot Password and Register Links */}
                        <div className="extra-links">
                            <Button href="/signup" className="register-link">
                                {t("No Account Yet? Register")}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
