import React, { useEffect, useState } from 'react';
import { TextField, Button, MenuItem, IconButton, Menu } from '@mui/material';
import { Facebook, Google, Apple, Language } from '@mui/icons-material';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import "../css/signup.css";
import login_image_card from "../images/login_image_card.png";
import { useNavigate } from 'react-router-dom';
import { AURCARDAPIBASE } from '../Utility/config';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import i18next, { t } from 'i18next';

const validationSchema = Yup.object({
    name: Yup.string().required('name is required'),
    email: Yup.string().email('Please enter a valid email address').required('Email is required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
    phone: Yup.string()
        .matches(/^\+(?:[0-9] ?){6,14}[0-9]$/, "Phone number is not valid")
        .required("phone Is Required"),
    companyName: Yup.string(),
});

const Signup = () => {
    const [language, setLanguage] = useState('en');
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem("auth_token")) {
            navigate("/");
        }
    }, []);

    const handleLanguageChange = (event) => {
        setLanguage(event.target.getAttribute('data-value'));
        i18next.changeLanguage(event.target.getAttribute('data-value'));
        document.querySelector("html").lang = event.target.getAttribute('data-value');
        localStorage.setItem("lang", event.target.getAttribute('data-value'))

        if (event.target.getAttribute('data-value') === "ar" || event.target.getAttribute('data-value') === "fa") {
            document.body.classList.remove("latino-font");
            document.body.classList.add("arabic-font");
        } else {
            document.body.classList.add("latino-font");
            document.body.classList.remove("arabic-font");
        }
        setAnchorEl(null);
    };

    const handleIconClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const signUp = async (values) => {
        try {
            const response = await axios.post(`${AURCARDAPIBASE}/register`, values);
            if (response?.data?.success) {
                const { token } = response?.data?.data;
                localStorage.setItem('auth_token', token);
                toast.success("Registration successfully");
                navigate('/');
            } else {
                toast.error("Registration failed");
            }
        } catch (error) {
            toast.error("Registration failed");
        }
    };

    return (
        <div className="signup alignByLang">
            <ToastContainer />
            <div className="signup-container signup-bk-image">
                <div className="signup-image">
                    <img src={login_image_card} alt="Image Card" />
                </div>
                <div className="signup-form">
                    <div className="signup-form-container">
                        <div className="signup-header">
                            <h2>{t("Register now")}</h2>
                            <IconButton
                                aria-controls="language-menu"
                                aria-haspopup="true"
                                onClick={handleIconClick}
                                sx={{ fontSize: 30 }}
                            >
                                <Language />
                            </IconButton>
                            <Menu
                                id="language-menu"
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={handleLanguageChange} data-value="en">English</MenuItem>
                                <MenuItem onClick={handleLanguageChange} data-value="ar">Arabic</MenuItem>
                            </Menu>
                        </div>
                        <p className="welcome-message">{t("Please enter your details to register.")}</p>

                        <Formik
                            initialValues={{
                                name: '',
                                email: '',
                                password: '',
                                phone: '',
                                companyName: '',
                            }}
                            validationSchema={validationSchema}
                            onSubmit={signUp}
                        >
                            {() => (
                                <Form>
                                    <div className="input-row-container">

                                        <div>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                label={t("Name")}
                                                variant="outlined"
                                                name="name"
                                                margin="normal"
                                                error={false}
                                                helperText={<ErrorMessage name="name" />}
                                            />
                                        </div>
                                    </div>

                                    <div className="input-row-container">
                                        <div>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                label={t("Email")}
                                                variant="outlined"
                                                name="email"
                                                margin="normal"
                                                error={false}
                                                helperText={<ErrorMessage name="email" />}
                                            />
                                        </div>
                                        <div>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                label={t("Password")}
                                                variant="outlined"
                                                name="password"
                                                type="password"
                                                margin="normal"
                                                error={false}
                                                helperText={<ErrorMessage name="password" />}
                                            />
                                        </div>
                                    </div>

                                    <div className="input-row-container">
                                        <div>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                label={t("Phone")}
                                                variant="outlined"
                                                name="phone"
                                                margin="normal"
                                                error={false}
                                                helperText={<ErrorMessage name="phone" />}
                                            />
                                        </div>
                                        <div>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                label={t("Company Name") + " " + t("(Optional)")}
                                                variant="outlined"
                                                name="companyName"
                                                margin="normal"
                                            />
                                        </div>
                                    </div>

                                    <Button variant="contained" type="submit" fullWidth className="signup-button">
                                        {t("Signup")}
                                    </Button>
                                </Form>
                            )}
                        </Formik>

                        <div className="social-signup-buttons">
                            <IconButton color="primary" aria-label="facebook" sx={{ fontSize: 30 }}>
                                <Facebook />
                            </IconButton>
                            <IconButton color="primary" aria-label="google" sx={{ fontSize: 30 }}>
                                <Google />
                            </IconButton>
                            <IconButton color="primary" aria-label="apple" sx={{ fontSize: 30 }}>
                                <Apple />
                            </IconButton>
                        </div>

                        <div className="extra-links">
                            <Button href="/login" className="register-link">
                                {t("Already have an account? Login")}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Signup;
