// import axios from 'axios';
// import {  toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import * as ReactDOMServer from "react-dom/server";


export const AURCARDAPIBASE = 'https://api.aurcard.com/api/v1';
export const AURCARDBASE = 'https://sandbox.aurcard.com';


const isUrl = (str) => {
    try {
        new URL(str);
        return true;
    } catch (e) {
        return false;
    }
};

const isBase64Image = (base64String) => {
    // Check if the string starts with a valid Base64 image data URI
    const imageRegex = /^data:image\/(png|jpeg|jpg|gif|bmp|webp);base64,/;

    // Test if the string matches the regex and try decoding it to ensure it's valid
    if (imageRegex.test(base64String)) {
        try {
            // Decode Base64 to binary
            const base64Data = base64String.split(',')[1]; // Get only the Base64 part
            atob(base64Data); // Decodes the string; throws an error if invalid
            return true;
        } catch (e) {
            return false; // Invalid Base64
        }
    }
    return false; // Doesn't match the image data URI pattern
};

export const getBase64OfImage = async (url = "") => {
    if (!isBase64Image(url)) {
        const authToken = localStorage.getItem('auth_token');
        if (!authToken) {
            return null;
        }

        try {
            const response = await axios.get(`${AURCARDAPIBASE}/url-to-base64?path=${url}`, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });


            const base64Data = response?.data?.data?.base64.replace(/\\/g, "")
            return base64Data;

        } catch (error) {
            return null;
        }
    } else {
        return url
    }

};



export const getSvgStyleBase64 = (imageSrc, shape_type, strokColor = "#FFFFFF") => {
    switch (shape_type) {
        case "shape1":
            return (
                <svg width="100%" height="100%" viewBox="100 0 400 900" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g style={{ mixBlendMode: "darken" }}>
                        <path opacity="0.3" d="M550.981 408.208L407.686 370.065L120.959 292.917L550.981 408.208Z" fill="white" />
                        <path d="M124.15 107.783L239.392 537.871L57.6286 356.122L124.15 107.783Z" fill={strokColor} />
                        <path d="M554.173 223.073L584.347 436.371L239.392 537.871L316.697 460.559L554.173 223.073Z" fill={strokColor} />
                        <path d="M554.172 223.073L239.392 537.87L124.15 107.782L410.878 184.931L554.172 223.073Z" fill="url(#pattern1)" />
                    </g>
                    <defs>
                        <pattern id="pattern1" patternUnits="userSpaceOnUse" width="100%" height="100%">
                            <image
                                href={imageSrc}
                                x="0"
                                y="0"
                                width="100%"
                                height="100%"
                                preserveAspectRatio="xMidYMid slice"
                            />
                        </pattern>
                    </defs>
                </svg>


            );
        case "shape2":
            return (
                <svg width="100%" height="100%" viewBox="80 0 430 908" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M82.8588 279.125C104.414 302.952 135.633 311.779 144.023 350.324C150.551 380.326 148.698 421.746 190.304 434.82C207.371 440.185 216.967 439.185 236.437 439.693C272.715 440.636 276.566 440.558 318.341 465.899C381.707 504.338 408.953 425.032 458.701 376.775C480.378 355.744 492.244 344.061 499.764 329.406C519.427 291.074 482.95 265.42 468.937 246.176C450.962 221.492 473.034 198.262 468.694 174.649C458.488 119.043 402.074 199.983 341.174 107.206C325.819 83.8123 295.661 72.5022 264.837 84.2666C223.362 100.095 216.586 141.654 170.384 151.436C162.306 153.146 139.063 156.865 124.678 160.464C71.6311 173.726 43.8316 235.986 82.8597 279.128L82.8588 279.125Z" fill="url(#paint0_radial_507_3274)" />
                    <path d="M536.062 388.57C531.146 351.524 506.999 322.491 524.494 280.015C538.112 246.954 567.353 208.471 536.043 168.522C523.2 152.132 513.319 146.766 494.944 133.45C460.711 108.639 456.957 106.177 433.616 54.3573C398.213 -24.2444 319.453 33.8195 239.657 47.295C204.884 53.1688 185.737 56.5491 168.796 65.6432C124.487 89.4287 142.532 138.058 143.237 165.738C144.144 201.244 107.534 208.969 96.0469 234.462C68.9884 294.489 176.858 254.078 173.865 383.142C173.111 415.684 194.587 446.401 232.009 455.436C282.355 467.593 316.421 432.222 367.299 453.292C376.193 456.976 400.993 468.726 417.204 474.758C476.967 497 544.958 455.641 536.059 388.568L536.062 388.57Z" fill="url(#pattern1)" />
                    <defs>
                        <radialGradient id="paint0_radial_507_3274" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(535.43 200.226) rotate(165.775) scale(498.79 501.83)">
                            <stop offset="0.132582" stop-color="#52AEFF" />
                            <stop offset="1" stop-color={strokColor} />
                        </radialGradient>
                    </defs>
                    <defs>
                        <pattern id="pattern1" patternUnits="objectBoundingBox" width="100%" height="100%">
                            <image
                                href={imageSrc}
                                x="0"
                                y="0"
                                width="100%"
                                height="100%"
                                preserveAspectRatio="xMidYMid slice"
                            />
                        </pattern>
                    </defs>
                </svg>
            );
        case "shape3":
            return (
                <svg width="100%" height="100%" viewBox="0 0 432 908" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M432 69.5V563.017C432 596.528 404.921 623.716 370.915 623.716C358.321 623.716 346.985 619.922 336.909 613.599L51.6385 451.101C20.7813 434.029 0 401.151 0 363.846V103.716C0 69.5724 16.3733 40.487 42.1925 22.7829L47 21.1058L313.909 16C327.5 16 335.95 16 347.915 16C381.921 16 432 35.3565 432 69.5Z" fill={strokColor} />
                    <path d="M432 53.5V547.017C432 580.528 404.921 607.716 370.915 607.716C358.321 607.716 346.985 603.922 336.909 597.599L51.6385 435.101C20.7813 418.029 0 385.151 0 347.846V87.7159C0 53.5724 16.3733 24.487 42.1925 6.78293L47 5.10575L313.909 4.57764e-05C327.5 4.57764e-05 335.95 0 347.915 0C381.921 0 432 19.3565 432 53.5Z" fill="url(#pattern1)" />
                    <defs>
                        <pattern id="pattern1" patternUnits="objectBoundingBox" width="100%" height="100%">
                            <image
                                href={imageSrc}
                                x="0"
                                y="0"
                                width="100%"
                                height="100%"
                                preserveAspectRatio="xMidYMid slice"
                            />
                        </pattern>
                    </defs>
                </svg>
            );
        case "shape4":
            return (
                <svg width="100%" height="100%" viewBox="0 0 430 400" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0" y="-17" width="500" height="274" fill="url(#pattern1)" />
                    <rect  y="257" width="450" height="16" fill={strokColor} />

                    <defs>
                        <pattern id="pattern1" patternUnits="objectBoundingBox" width="100%" height="100%">
                            <image
                                href={imageSrc}
                                x="0"
                                y="0"
                                width="100%"
                                height="100%"
                                preserveAspectRatio="xMidYMid slice"
                            />
                        </pattern>
                    </defs>
                </svg>
            );
        case "shape5":
            return (
                <svg width="100%" height="100%" viewBox="0 0 430 400" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 11H430V221C430 238.673 415.673 253 398 253H32C14.3269 253 0 238.673 0 221V11Z" fill={strokColor} />
                    <path d="M0 0H430V210C430 227.673 415.673 242 398 242H32C14.3269 242 0 227.673 0 210V0Z" fill="url(#pattern1)" />

                    <defs>
                        <pattern id="pattern1" patternUnits="objectBoundingBox" width="100%" height="100%">
                            <image
                                href={imageSrc}
                                x="0"
                                y="0"
                                width="100%"
                                height="100%"
                                preserveAspectRatio="xMidYMid slice"
                            />
                        </pattern>
                    </defs>
                </svg>

            );
        default:
            return null;
    }
}



export const getSvgImageUri =  (image_src, shape_type, strokColor = "#FFFFFF") => {
    const svgString = encodeURIComponent(
        ReactDOMServer.renderToStaticMarkup(getSvgStyleBase64(image_src, shape_type, strokColor))
    );
    const svgImageUrl = `url("data:image/svg+xml,${svgString}")`;
    return svgImageUrl;
}