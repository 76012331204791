import React, { useState, useEffect, useRef } from 'react';
import { Stepper, Step, StepLabel, Button, Box, Container } from '@mui/material';
import BasicInfoForm from './steps/BasicInfoForm';
import ContactWayForm from './steps/ContactWayForm';
import CardStyleForm from './steps/CardStyleForm';
import QRCodeConfigForm from './steps/QRCodeConfigForm';
import "../../css/CreateCardStepper.css";

const steps = ['Basic Info', 'Contact Way', 'Card Style', 'QRCode Config'];

const CreateCardStepper = ({ onCreateCard, initialData, editFlag, from_group, group_data }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [cardData, setCardData] = useState(editFlag ? initialData : {
    basicinfo: {},
    contactway: [],
    cardstyle: {},
    qrcodeconfig: {}
  });
  
  const formRef = useRef(null);

  useEffect(() => {
    if (editFlag && initialData) {
      setCardData(initialData);
    }
  }, [editFlag, initialData]);

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1)
  };
  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  const handleSaveData = (stepData) => {
    const stepKey = steps[activeStep].toLowerCase().replace(' ', '');

    setCardData((prevData) => {
      let updatedData;
      if (stepKey === "contactway") {
        updatedData = {
          ...prevData,
          [stepKey]: Array.isArray(prevData[stepKey])
            ? [...(stepData.length ? stepData : [])]
            : stepData
        };
      } else {
        updatedData = {
          ...prevData,
          [stepKey]: { ...prevData[stepKey], ...stepData }
        };
      }

      if (activeStep === steps.length - 1) {
        onCreateCard(updatedData);
      } else {
        handleNext();
      }

      return updatedData;
    });
  };

  const renderStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return <BasicInfoForm onSave={handleSaveData} handleBack={handleBack} cardData={cardData} from_group={from_group} group_data={group_data} />;
      case 1:
        return <ContactWayForm onSave={handleSaveData} handleBack={handleBack} cardData={cardData} />;
      case 2:
        return <CardStyleForm onSave={handleSaveData} handleBack={handleBack} cardData={cardData} from_group={from_group} group_data={group_data} />;
      case 3:
        return <QRCodeConfigForm onSave={handleSaveData} handleBack={handleBack} cardData={cardData} />;
      default:
        return null;
    }
  };

  return (
    <Container sx={{ maxWidth: 'sm', padding: { xs: 1, sm: 3 }, marginTop: { xs: 2, sm: 5 } }}>
      <Box>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box my={4}>{renderStepContent(activeStep)}</Box>
        <Box display="flex" justifyContent="space-between" flexDirection={{ xs: 'column', sm: 'row' }} mt={3}>
        </Box>
      </Box>
    </Container>
  );
};

export default CreateCardStepper;
