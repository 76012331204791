import { Avatar, Box, DialogContent, IconButton, Typography, Button, Backdrop, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { AURCARDAPIBASE, getBase64OfImage, getSvgImageUri } from "../Utility/config";
import { useParams } from "react-router-dom";
import placeholder_image from "../images/placeholder_image.png";
import logo from "../images/aurages.png";



const CardScreen = () => {
    const [cardData, setCardData] = useState(null);
    const [loading, setLoading] = useState(false);
    const { slug } = useParams();
    const [imageSrcData, setImageSrcData] = useState(placeholder_image || "");

    useEffect(() => {
        if (slug) {
            setLoading(true);
            const authToken = localStorage.getItem('auth_token');
            axios
                .get(`${AURCARDAPIBASE}/qr/${slug}`, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                })
                .then((response) => {
                    setCardData(response?.data?.data);
                    getBase64OfImage(response?.data?.data?.basicinfo?.cover || placeholder_image).then((base64) => {
                        setImageSrcData(base64)
                    });
                    setLoading(false);
                })
                .catch((error) => {
                    console.error('Error fetching card data:', error);
                    setCardData(window.cardData);
                    setLoading(false);
                });
        } else {
            setCardData(window.cardData);
        }
    }, []);

    const alignment = cardData?.cardstyle?.avatar_align || 'center';

    const handleOpenContact = (contact) => {
        window.open(`${contact.href_prefix}${contact.link}`, '_blank');
    };

    const handleSaveContact = () => {
        let vCardContent = '';

        vCardContent += `BEGIN:VCARD\n`;
        vCardContent += `VERSION:3.0\n`;

        if (cardData?.basicinfo?.name) {
            vCardContent += `FN:${cardData.basicinfo.name}\n`;
        }

        if (cardData?.basicinfo?.job_title) {
            vCardContent += `TITLE:${cardData.basicinfo.job_title}\n`;
        }

        if (cardData?.basicinfo?.avatar) {
            vCardContent += `PHOTO;VALUE=URI:${cardData.basicinfo.avatar}\n`;
        }

        cardData?.contactway.forEach((contact) => {
            if (contact.name === "Phone") {
                vCardContent += `TEL:${contact.href_prefix}${contact.link}\n`;
            } else if (contact.name === "Email") {
                vCardContent += `EMAIL:${contact.link}\n`;
            } else if (contact.name === "Website" || contact.name === "Linked In" || contact.name === "Telegram" || contact.name === "X" || contact.name === "Instagram") {
                vCardContent += `URL:${contact.link}\n`;
            }
        });

        vCardContent += `END:VCARD\n`;

        const blob = new Blob([vCardContent], { type: 'text/vcard' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'contact_info.vcf';
        link.click();
    };

    return (
        <div style={{ height: "100%" }}>
            <Box
                sx={{
                    backgroundColor: cardData?.cardstyle?.background_color || '#ffffff',
                    width: '100%',
                    minHeight: '100vh',
                }}
            >
                <Backdrop open={loading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                {!loading ?
                    <>
                        {!cardData?.cardstyle?.style_shape || cardData?.cardstyle?.style_shape === "none" ?
                            <>
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: { xs: 180, sm: 250 },
                                        backgroundImage: `url(${cardData?.basicinfo?.cover || placeholder_image})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        border: `2px solid ${cardData?.cardstyle?.cover_border_color || '#000000'}`,
                                        marginBottom: 6,
                                        position: 'relative',
                                    }}
                                >
                                    <Avatar
                                        src={cardData?.basicinfo?.avatar || placeholder_image}
                                        alt="Profile"
                                        sx={{
                                            width: { xs: 130, sm: 170, md: cardData?.cardstyle?.avatar_shape === 'rectangle' ? 190 : 170 },
                                            height: { xs: 130, sm: 170 },
                                            border: `3px solid ${cardData?.cardstyle?.avatar_border_color || '#000000'}`,
                                            borderRadius: cardData?.cardstyle?.avatar_shape === 'circle' ? '50%' : '0%',
                                            position: 'absolute',
                                            bottom: -65,
                                            left: alignment === 'left' ? 20 : alignment === 'center' ? '50%' : 'auto',
                                            right: alignment === 'right' ? 20 : 'auto',
                                            transform: alignment === 'center' ? 'translateX(-50%)' : 'none',
                                        }}
                                    />
                                </Box>

                                <Box sx={{ textAlign: alignment, marginTop: 10 }}>
                                    <Typography variant="h5" sx={{ fontWeight: 'bold', color: cardData?.cardstyle?.text_color || '#000000' }}>
                                        {cardData?.basicinfo?.name || ""}
                                    </Typography>

                                    <Typography variant="subtitle1" sx={{ color: cardData?.cardstyle?.text_color || '#000000', marginBottom: 2 }}>
                                        {cardData?.basicinfo?.job_title || ""}
                                    </Typography>
                                </Box>
                            </>
                            :
                            <>
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: 300,
                                        backgroundImage: getSvgImageUri(imageSrcData || placeholder_image, cardData?.cardstyle?.style_shape, "#00f"),
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        borderRadius: 0,
                                        border: `0px solid ${cardData?.cover_border_color}`,
                                        position: 'relative',
                                        padding: 1
                                    }}
                                >
                                    <Avatar
                                        src={cardData?.basicinfo?.avatar || placeholder_image}
                                        alt="Profile"
                                        sx={{
                                            width: cardData?.cardstyle?.style_shape ? 90 : 100,
                                            height: cardData?.cardstyle?.style_shape ? 90 : 100,
                                            border: `4px solid ${cardData?.avatar_border_color}`,
                                            borderRadius: cardData?.cardstyle?.style_shape === "shape1" ? '7%' : cardData?.cardstyle?.style_shape === "shape2" ? '50%' : cardData?.cardstyle?.style_shape === "shape3" ? '50%' : cardData?.cardstyle?.style_shape === "shape4" ? '50%' : cardData?.cardstyle?.style_shape === "shape5" ? '7%' : '0%',
                                            position: 'absolute',
                                            bottom: cardData?.cardstyle?.style_shape === "shape1" ? 100 : cardData?.cardstyle?.style_shape === "shape2" ? 100 : cardData?.cardstyle?.style_shape === "shape3" ? 100 : cardData?.cardstyle?.style_shape === "shape4" ? -10 : cardData?.cardstyle?.style_shape === "shape5" ? 120 : "auto",//shap1
                                            left: cardData?.cardstyle?.style_shape === "shape1" ? 10 : cardData?.cardstyle?.style_shape === "shape2" ? 10 : cardData?.cardstyle?.style_shape === "shape3" ? 10 : 'auto',//shap1
                                            right: cardData?.cardstyle?.style_shape === "shape4" ? 20 : 'auto',
                                            transform: cardData?.avatar_align === 'center' ? 'translateX(-50%)' : 'none',
                                        }}
                                    />

                                    <Box sx={{
                                        textAlign: alignment,


                                        position: 'absolute',
                                        bottom: cardData?.cardstyle?.style_shape === "shape1" ? -10 : cardData?.cardstyle?.style_shape === "shape2" ? 0 : cardData?.cardstyle?.style_shape === "shape3" ? 0 : cardData?.cardstyle?.style_shape === "shape4" ? -10 : cardData?.cardstyle?.style_shape === "shape5" ? 0 : "auto",//shap1
                                    }}>
                                        <Typography variant="h5" sx={{ fontWeight: 'bold', color: cardData?.cardstyle?.text_color || '#000000' }}>
                                            {cardData?.basicinfo?.name || ""}
                                        </Typography>

                                        <Typography variant="subtitle1" sx={{ color: cardData?.cardstyle?.text_color || '#000000', marginBottom: 2 }}>
                                            {cardData?.basicinfo?.job_title || ""}
                                        </Typography>
                                    </Box>
                                </Box>

                            </>
                        }




                        {/* here we will edit and replace formData with cardData */}

                        {/* <Box
                            sx={{
                                width: '100%',
                                height: 280,
                                backgroundImage: formData?.cardData?.cardstyle?.style_shape === "none" ? `url(${cardData?.basicinfo?.cover || placeholder_image})` : getSvgImageUri(cardData?.basicinfo?.cover || placeholder_image, formData?.cardData?.cardstyle?.style_shape, formData.cover_border_color),
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                borderRadius: formData?.cardData?.cardstyle?.style_shape === "none" ? 2 : 0,
                                border: `${formData?.cardData?.cardstyle?.style_shape === "none" ? "5px" : "0px"} solid ${formData.cover_border_color}`,
                                marginBottom: 2,
                                position: 'relative',
                            }}
                        >
                            <Avatar
                                src={cardData?.basicinfo?.avatar || placeholder_image}
                                alt="Profile"
                                sx={{
                                    width: formData.avatar_shape === 'rectangle' ? 120 : 100,
                                    height: 100,
                                    border: `4px solid ${formData.avatar_border_color}`,
                                    borderRadius: formData.avatar_shape === 'circle' ? '50%' : formData.avatar_shape === 'square' ? '0%' : '0%',
                                    position: 'absolute',
                                    bottom: -10,
                                    // Align based on the selected value of formData.avatar_align
                                    left: formData.avatar_align === 'left' ? 20 : formData.avatar_align === 'right' ? 'auto' : '50%',
                                    right: formData.avatar_align === 'right' ? 20 : 'auto',
                                    transform: formData.avatar_align === 'center' ? 'translateX(-50%)' : 'none',
                                }}
                            />
                        </Box> */}




                        {cardData?.contactway?.length > 0 && (
                            <Box sx={{ marginTop: 1, textAlign: alignment }}>
                                <Box sx={{ textAlign: 'center', marginBottom: 2 }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSaveContact}
                                        sx={{ padding: 1.2, width: 200 }}
                                    >
                                        Save Contact
                                    </Button>
                                </Box>

                                <DialogContent sx={{ padding: 2 }}>
                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns:
                                                cardData?.contactway?.length < 3
                                                    ? `repeat(${cardData.contactway.length}, 1fr)` // Adjust to number of contacts
                                                    : { xs: 'repeat(3, 1fr)', sm: 'repeat(2, 1fr)', md: 'repeat(auto-fill, minmax(120px, 1fr))' },
                                            gap: { xs: 2, sm: 5 },
                                            justifyContent: cardData?.contactway?.length < 3 ? 'center' : 'start',
                                            maxWidth: { xs: 300, sm: 500 },
                                            margin: '0 auto',
                                            marginBottom: 5

                                        }}
                                    >
                                        {cardData?.contactway?.map((contact) => (
                                            <Box
                                                key={contact.name}
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    textAlign: 'center',
                                                    padding: { xs: 0.5, sm: 1 },
                                                    borderRadius: 2,
                                                    boxShadow: 2,
                                                    backgroundColor: '#f0f0f0',
                                                    '&:hover': {
                                                        backgroundColor: '#e0e0e0',
                                                        cursor: 'pointer',
                                                    },
                                                }}
                                                onClick={() => handleOpenContact(contact)}
                                            >
                                                <IconButton>
                                                    <img src={contact.icon} alt={contact.name} style={{ width: 50, height: 50 }} />
                                                </IconButton>
                                                <Typography variant="body2" sx={{ marginTop: { xs: 0, sm: 1 } }}>
                                                    {contact.title || contact.name}
                                                </Typography>
                                            </Box>
                                        ))}

                                    </Box>
                                </DialogContent>

                            </Box>
                        )}

                    </>
                    :
                    true
                }

                <Box
                    sx={{
                        position: "fixed",
                        bottom: 0,
                        width: '100%',
                        textAlign: 'center',
                        padding: 1,
                        backgroundColor: '#f0f0f0',
                        color: '#000000',

                    }}
                >
                    Powered by Aurages{" "}
                    <a href="https://aurages.com/" target="_blank" rel="noopener noreferrer">
                        <img style={{ width: 80, height: 20 }} src={logo} alt="Aurages" />
                    </a>
                </Box>

            </Box>

        </div>

    );
};

export default CardScreen;
